import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../styles/recruit_interview.module.scss"
import Woman01 from "../../images/recruit-icon-woman01.png"
import Woman02 from "../../images/recruit-icon-woman02.png"

const Interview = () => {
  return (
    <Layout>
      <Seo
        title="社員インタビュー | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={`${style.mv} ${style.person01}`}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>社員インタビュー</h2>
          </div>
        </div>
      </div>
      <div className={style.contentsWrap}>
        <section className={style.defaultSection}>
          <h3>企画・営業</h3>
          <div className={style.defaultSection__inner}>
            <div className={style.defaultSection__innerLeft}>
              <div className={style.interviewIcon}>
                <img src={Woman01} alt="規格・営業" />
              </div>
              <div className={style.staffProfile}>
                <p>
                  <b>K.Nさん（中途入社2年目）</b>
                </p>
                <p>ECサイト運営スタッフ</p>
                <p>前職：ECサイト運営スタッフ</p>
              </div>
            </div>
            <div className={style.defaultSection__innerRight}>
              <h4>仕事のおもしろさはなんですか？</h4>
              <p>
                商品の買い付けから携われるため、自分が本当に良いと思ったものをお客様へ提案できます。そんな愛着ある商品を店頭で目にしたときや、身近な友達が持っていた、という話を閻いたときは、とてもやりがいを感じます。
              </p>
              <h4>職場環境について教えてください。</h4>
              <p>
                20代の若い社員からベテランまで幅広く在籍しており、挑戦的でありつつも、落ち着きもあるバランスの良い職場です。みなさん気さくで優しい方ばかりなので、質問や相談も気兼ねなくできますよ。
              </p>
              <h4>会社の魅力はどこだと思いますか？</h4>
              <p className={style.mb0}>
                新人でも、やりたいことにどんどん挑戦できるところです。
                <br />
                今は中国出展に行けることを楽しみにしています。
              </p>
            </div>
          </div>
        </section>

        <section className={style.defaultSection}>
          <h3>EC事業部</h3>
          <div className={style.defaultSection__inner}>
            <div className={style.defaultSection__innerLeft}>
              <div className={style.interviewIcon}>
                <img src={Woman02} alt="規格・営業" />
              </div>
              <div className={style.staffProfile}>
                <p>
                  <b>K.Nさん（中途入社2年目）</b>
                </p>
                <p>ECサイト運営スタッフ</p>
                <p>前職：ECサイト運営スタッフ</p>
              </div>
            </div>
            <div className={style.defaultSection__innerRight}>
              <h4>何故この仕事を選んだのですか？</h4>
              <p>
                前職ではBtoB向けECサイトを担当。Amazonなどの大型モールで商品展開できるBtoC向けECサイトに興味があったのと、老舗ながらSNSを活用した商品PRなど、新しいことに挑戦する当社の企業姿勢に魅力を感じたためです。
              </p>
              <h4>仕事のおもしろさはなんですか？</h4>
              <p>
                やはり自分が携わった商品が売れることです。また、ページの更新や運用だけではなく、インスタグラマーとコラボした商品PRや、営業と連携して新しいアイテムを仕入れることも。様々なチャレンジを楽しめる環境です！
              </p>
              <h4>職場環境について教えてください。</h4>
              <p className={style.mb0}>
                現在EC事業部は5名のコンパクトなチームです。
                <br />
                思いついたときに自由に意見が言える環境ですし、縦割りではなくさまざまな業務い携われるので、幅広いスキルを身に付けられると思います。
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Interview
